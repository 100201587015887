<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <dashboard-stats :data="data" />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import DashboardStats from './DashboardStats.vue'

export default {
  components: {
    BRow,
    BCol,
    ToastificationContent,

    DashboardStats,
  },
  data() {
    return {
      data: [],
    }
  },
  created() {
      this.$http.get('/admin/v1/dashboard')
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
          
            this.data = [
              {
                icon: "ChevronsUpIcon",
                color: "light-success",
                title: currentData.upcoming_events_count,
                subtitle: "Upcoming Events",
                customClass: "mb-2 mb-xl-0"
              },
              {
                icon: "CalendarIcon",
                color: "light-warning",
                title: currentData.all_events_count,
                subtitle: "All Events",
                customClass: "mb-2 mb-xl-0"
              },
              // {
              //   icon: "UserPlusIcon",
              //   color: "light-primary",
              //   title: currentData.new_users_count,
              //   subtitle: "New Users",
              //   customClass: "mb-2 mb-xl-0"
              // },
              // {
              //   icon: "UserIcon",
              //   color: "light-info",
              //   title: currentData.users_count,
              //   subtitle: "All Users",
              //   customClass: "mb-2 mb-xl-0"
              // },
            ]
          }
     })
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
